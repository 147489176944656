<template>
  <ion-list lines="full">
    <ion-item
      v-for="planGame in planGames"
      :key="planGame.id"
      :router-link="{ name: 'PlanGameDetails', params: { planId: plan.id, planGameId: planGame.id } }"
      :color="isPlanGameComplete(planGame) ? 'success' : ''"
    >
      <ion-label>
        <h2>{{ getGame(planGame.gameId).title }}</h2>
        <p>{{ getGame(planGame.gameId).description }}</p>

        <div v-if="Object.keys(getGame(planGame.gameId).configs).length > 0" class="configs">
          <div v-if="getGame(planGame.gameId).configs.club" class="config">
            <h3>{{ planGame.config.club }}</h3>
            <div>Club</div>
          </div>
          <div v-if="getGame(planGame.gameId).configs.distance" class="config">
            <h3>{{ planGame.config.distance }}yds</h3>
            <div>Distance</div>
          </div>
        </div>
      </ion-label>
    </ion-item>
  </ion-list>
</template>

<script>

import {
  IonList, 
  IonItem,
  IonLabel,
} from '@ionic/vue';

export default {
  name: 'PlanGamesList',
  inject: ['global'],
  components: {
    IonList,
    IonItem,
    IonLabel,
  },

  props: {
    planGames: {
      type: Array,
      default: () => [],
    },

    plan: {
      type: Object,
      default: null,
    },

    planSession: {
      type: Object,
      default: null,
    },
  },

  setup() {
    return {
    };
  },

  created() {
  },

  data() {
    return {
      colours: [
        'primary',
        'secondary',
        'tertiary',
        'success',
        'warning',
        'danger',
      ].sort(),

      colourAssign: {},
    };
  },

  methods: {
    getColour(val) {
      const colour = this.colourAssign[val] || this.colours[Object.keys(this.colourAssign).length] || this.colours[0];

      this.colourAssign[val] = colour;

      return colour;
    },

    // todo: this is innefficient to rerun every time!
    getGame(gameId) {
      return this.global.games.getById(gameId);
    },

    getPlanGameSession(planGameId, planSessionId) {
      return this.global.gameSessions.getByPlanGameIdAndPlanSessionId(planGameId, planSessionId);
    },

    isPlanGameComplete(planGame) {
      const planGameSession = this.getPlanGameSession(planGame.id, this.planSession?.id);

      return planGameSession && planGameSession.completedAt;
    }
  },
}
</script>

<style scoped lang="scss">
  ion-icon {
    margin-right: 1rem;
  }

  .configs {
    display: flex;
    margin: 1rem;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;

    .config {
      margin: 0 1.5rem;

      h3 {
        font-size: 1.1rem;
        margin: 0;
      }

      > div {
        text-transform: uppercase;
        font-weight: bold;
        color: #666;
        font-size: 1rem;
      }
    }
  }

  .complete {
    ion-item::part(native) {
    //.item-inner {
      background: green;
    //}
    }
  }

</style>