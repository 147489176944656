<template>
  <ion-page>
    <template v-if="plan">
      <ion-header :translucent="true">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button color="primary"></ion-menu-button>
          </ion-buttons>
          <ion-title>{{ plan.title }}</ion-title>
        </ion-toolbar>
      </ion-header>
      
      <ion-content :fullscreen="true">
        <div id="container">
          <div class="info">
              {{ plan.description }}
          </div>

          <div class="games">
            <h3>Games</h3>
            <PlanGamesList :planGames="planGames" :plan="plan" />
          </div>

        </div>
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          <ion-button expand="full" size="large" @click="onStartPlan">
            Start Plan
          </ion-button>
        </ion-toolbar>
      </ion-footer>
    </template>
  </ion-page>
</template>

<script>
import {
  IonButtons, 
  IonContent, 
  IonHeader, 
  IonMenuButton, 
  IonPage, 
  IonTitle, 
  IonToolbar,
  IonButton,
  IonFooter,
} from '@ionic/vue';
import { golfSharp } from 'ionicons/icons';

import PlanGamesList from '@/components/games/PlanGamesList';

export default {
  name: 'PlanDetails',
  inject: ['global'],
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonFooter,

    PlanGamesList,
  },

  setup() {

    return {
      golfSharp
    };
  },

  created() {
  },

  data() {
    return {

    };
  },

  computed: {
    planId() {
      return parseInt(this.$route.params.id, 10);
    },

    plan() {
      return this.global.plans.getById(this.planId);
    },

    planGames() {
      return this.plan?.planGames || [];
    },
  },

  methods: {
    onStartPlan() {
      const planSession = this.global.planSessions.createPlanSession(this.planId);
      const firstGame = this.plan.planGames[0];

      const gameSession = this.global.gameSessions.createGameSession(firstGame.gameId, firstGame.id, planSession.id, { ...firstGame.config });

      this.$router.push({
        name: 'PlanSessionDetails',
        params: {
          planSessionId: planSession.id,
        },
      });

      this.$router.push({
        name: 'PlanGameSession',
        params: {
          id: gameSession.id,
          planId: this.plan.id,
          planSessionId: planSession.id,
        },
      });
    },
  },
}
</script>

<style scoped lang="scss">
  ion-icon {
    margin-right: 1rem;
  }

  .info {
    margin: 2rem 2rem 1rem;
    font-weight: bold;
    text-align: center;
  }

  .games {
    h3 { 
      margin: 1rem;
    }
  }
</style>